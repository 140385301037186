export const SET_IS_SHOW_ADMIN_TOOLS_MENU = 'SET_IS_SHOW_ADMIN_TOOLS_MENU'

const state = () => {
  return {
    menu: [
      {
        icon: 'BoxIcon',
        pageName: 'customer-portal',
        title: 'Customer Portal',
        subMenu: [
          {
            icon: 'UserIcon',
            pageName: 'admins',
            title: 'Users'
          },
          {
            icon: 'CheckIcon',
            pageName: 'projects',
            title: 'Projects'
          },
          {
            icon: 'FolderPlusIcon',
            pageName: 'dataRequests',
            title: 'Data Requests'
          }
        ]
      }
    ],
    adminToolsMenu: [
      {
        icon: 'ServerIcon',
        pageName: 'endole',
        title: 'Endole'
      },
      {
        icon: 'ShuffleIcon',
        pageName: 'ScrapEndoleMapping',
        title: 'Scrap Endole Mapping'
      },
      {
        icon: 'ListIcon',
        pageName: 'scrapEndole',
        title: 'Scrap Endole'
      },
      {
        icon: 'RefreshCwIcon',
        pageName: 'SyncOrganisationStatus',
        title: 'Sync Organisation Status'
      },
      {
        icon: 'ServerIcon',
        pageName: 'SyncOrganisationJobs',
        title: 'Sync Organisation Jobs'
      },
      'devider',
      {
        icon: 'ArrowLeftCircleIcon',
        pageName: 'secPortal',
        title: 'SEC Portal'
      }
    ]
  }
}

// getters
const getters = {
  // menu: state => state.menu,
  menu(state, getters, rootState, rootGetters) {
    const menus = JSON.parse(JSON.stringify(state.menu))
    if (
      rootGetters.currentPermissions.includes('DataRequestTemplate-Read')
    ) {
      menus[0].subMenu.splice(3, 0, {
        icon: 'BriefcaseIcon',
        pageName: 'dataRequestTemplates',
        title: 'Data Request Templates'
      })
    }
    if (
      rootGetters.currentPermissions.includes('Organisation-Read')
    ) {
      menus[0].subMenu.splice(0, 0, {
        icon: 'BriefcaseIcon',
        pageName: 'organisations',
        title: 'Organisations'
      })
    }
    if (
      rootGetters.currentPermissions.includes('Settings-Read')
    ) {
      menus[0].subMenu.splice(0, 0, {
        icon: 'UserIcon',
        pageName: 'adminEmailTemplates',
        title: 'Email Templates'
      })

      menus[0].subMenu.splice(2, 0, {
        icon: 'BookOpenIcon',
        pageName: 'documentTypes',
        title: 'Document Types'
      })

      menus.push({
        icon: 'BoxIcon',
        pageName: 'integration',
        title: 'Integration',
        subMenu: [
          {
            icon: 'BriefcaseIcon',
            pageName: 'typeformNdaRequest',
            title: 'Typeform NDA Request'
          }
        ]
      })

      menus.push({
        icon: 'BoxIcon',
        pageName: 'data-ecosystem',
        title: 'Data Ecosystem',
        subMenu: [
          {
            icon: 'BriefcaseIcon',
            pageName: 'ecosystemMasters',
            title: 'Masters'
          },
          {
            icon: 'BriefcaseIcon',
            pageName: 'ecosystemImportLogs',
            title: 'Import Logs'
          }
        ]
      })

      menus.push({
        icon: 'BoxIcon',
        pageName: 'help-setting',
        title: 'Help Settings',
        subMenu: [
          {
            icon: 'BriefcaseIcon',
            pageName: 'helpsections',
            title: 'Section'
          },
          {
            icon: 'BriefcaseIcon',
            pageName: 'helplinks',
            title: 'Link'
          }
        ]
      })

      menus.push({
        icon: 'BoxIcon',
        pageName: 'setting',
        title: 'Settings',
        subMenu: [
          {
            icon: 'UserIcon',
            pageName: 'ndaDocusignSettings',
            title: 'Nda Docusign Settings'
          },
          {
            icon: 'DatabaseIcon',
            pageName: 'mySqlConfigurations',
            title: 'MySQL Configurations'
          },
          {
            icon: 'ShuffleIcon',
            pageName: 'pipedriveFields',
            title: 'Pipedrive Field Mapping'
          }
        ]
      })
    }

    menus.push({
      icon: 'TrelloIcon',
      pageName: 'helpArea',
      title: 'Help'
    })
    if (
      rootGetters.currentRoles.includes('SuperAdmin') &&
      rootGetters.isDisplayAppSettings
    ) {
      menus.push({
        icon: 'TrelloIcon',
        pageName: 'checkAppSettings',
        title: 'Check App Settings'
      })
    }
    
    if (
      rootGetters.currentPermissions.includes('Endole-Read') ||
      rootGetters.currentPermissions.includes('ScrapEndoleMapping-Read')
    ) {
      menus.push('devider')
      menus.push({
        icon: 'ToolIcon',
        pageName: 'adminTools',
        title: 'Admin Tools'
      })
    }

    if (
      rootGetters.currentRoles.every(e => e === 'Salesman')
    ) {
      menus[0].subMenu.splice(0, 1)
    }

    return menus
  },
  adminToolsMenu(state, getters, rootState, rootGetters) {
    const menus = JSON.parse(JSON.stringify(state.adminToolsMenu))

    return menus
  }
}

// actions
const actions = {
  SetIsShowAdminToolsMenu ({ commit }, data) {
    commit(SET_IS_SHOW_ADMIN_TOOLS_MENU, data)
  }
}

// mutations
const mutations = {
  [SET_IS_SHOW_ADMIN_TOOLS_MENU](state, data) {
    state.isShowAdminToolsMenu = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

// {
//   icon: 'HomeIcon',
//   pageName: 'side-menu-dashboard',
//   title: 'Dashboard',
//   subMenu: [
//     {
//       icon: '',
//       pageName: 'side-menu-dashboard-overview-1',
//       title: 'Overview 1'
//     },
//     {
//       icon: '',
//       pageName: 'side-menu-dashboard-overview-2',
//       title: 'Overview 2'
//     },
//     {
//       icon: '',
//       pageName: 'side-menu-dashboard-overview-3',
//       title: 'Overview 3'
//     }
//   ]
// },
